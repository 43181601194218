<script>
    let className = '';
    export { className as class };  
    export let xs = false;
    export let sm = false;
    export let lg = false;
    export let xl = false;
    export let invert = false;
    export let fluid = false;

    /** @type {'div' | 'article' | 'section'} */
    export let tag = 'div';
</script>

<svelte:element
    this={tag}
    class="prose-headings:mb-2 {className}
        {invert ? 'prose-invert' : ''}
        {
        xs
        ? 'prose max-sm:prose-sm'
        : sm
        ? 'prose max-sm:prose-sm md:prose-lg'
        : lg
        ? 'prose md:prose-xl'
        : xl
        ? 'prose-lg md:prose-2xl'
        : 'prose md:prose-lg'}

        {fluid ? 'max-w-none w-full' : ''}
    "
>
    <slot />
</svelte:element>
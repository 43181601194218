import { browser, dev } from '$app/environment';

let isInstalled = false;
let triedToInstall = false;

const params_of_interest = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'ref', 'z'];

/** @type {{name:string, data:{}}[]} */
let eventQueue = [];

export function install() {
    if (!isInstalled && !triedToInstall && browser) {
        triedToInstall = true;

        let d = document;
        let s = d.createElement('script');
        s.async = true;
        d.body.appendChild(s);

        s.onload = () => {
            isInstalled = true;
            eventQueue.forEach((e) => {
                logEvent(e.name, e.data);
            });
            eventQueue = [];
        };

        s.src = `https://t.yawplan.com/latest${dev ? '.dev' : ''}.js`;
    }
}

export function logEvent(name = '', data = {}) {
    if (!browser) {
        return;
    }

    const data_params = get_params_of_interest();

    if (window.sa_event) {
        window.sa_event(name, {...data, ...data_params});
    } else {
        eventQueue.push({ name, data: {...data, ...data_params} });
    }

    install();
}


export const save_params_of_interest = () => {
    if (!browser) return;
    
    const stored_params_str = localStorage.getItem("c_ids");
    let stored_params_obj = {};
    
    if(stored_params_str) {
        try {
            stored_params_obj = JSON.parse(stored_params_str);
        } catch (e) {
            console.error('Error parsing stored params', e);
        }
    }
    const new_params = new URLSearchParams(location.search);
    let new_params_obj = {};
    for (let param of params_of_interest) {
        if(new_params.get(param)) new_params_obj[param] = new_params.get(param);
    }

    localStorage.setItem("c_ids", JSON.stringify({...stored_params_obj, ...new_params_obj}));
}

export const get_params_of_interest = () => {
    if (!browser) return {};
    const stored_params_str = localStorage.getItem("c_ids");
    try {
        return stored_params_str ? JSON.parse(stored_params_str) : {};
    } catch (error) {
        console.error('Error parsing stored params:', error);
        return {};
    }
}

export const track_purchase = (price) => {
    console.log('track_purchase', price);
    if (typeof window.fbq === 'function') window.fbq('track', 'Purchase', {
        value: price,
        currency: 'USD'
    });

    if (typeof window.twq === 'function') window.twq('event', 'tw-onejy-onek0', {
        value: price,
        currency: 'USD'
    });
}

export const track_initiate_checkout = () => {
    if (window?.fbq) window?.fbq('track', 'InitiateCheckout');
    //this is made up 
    // if (window?.twq) window?.twq('track', 'InitiateCheckout');
}
import { browser } from '$app/environment';
import { PUBLIC_ENABLE_CHECKOUT, PUBLIC_ORIGIN, PUBLIC_PRODUCTION } from '$env/static/public';
import { get, writable } from 'svelte/store';


export const site_origin = PUBLIC_ORIGIN;

export const isProd = PUBLIC_PRODUCTION === 'true';

export const isCheckoutEnabled = PUBLIC_ENABLE_CHECKOUT === 'true';

export const isIOS = browser
    ? navigator?.userAgent.match(/(iPad|iPhone|iPod)/g)
    : false;
export const isMac = browser ? navigator.userAgent.match(/(Mac)/g) : false;

export const cookie_consent_asked = writable(false);
export const cookie_consent_preferences = writable(false);
export const cookie_consent_marketing = writable(false);
export const cookie_consent_statistics = writable(false);

if (browser) {
    let consent_preferences =
        localStorage.getItem('cookie_consent_preferences') === 'true' ||
        document.cookie.includes('cookie_consent_preferences=true');
    let consent_asked =
        localStorage.getItem('cookie_consent_asked') === 'true' ||
        document.cookie.includes('cookie_consent_asked=true');
    let consent_marketing =
        localStorage.getItem('cookie_consent_marketing') === 'true' ||
        document.cookie.includes('cookie_consent_marketing=true');
    let consent_statistics =
        localStorage.getItem('cookie_consent_statistics') === 'true' ||
        document.cookie.includes('cookie_consent_statistics=true');

    if (consent_preferences)
        cookie_consent_preferences.set(consent_preferences);
    if (consent_asked) cookie_consent_asked.set(consent_asked);
    if (consent_marketing) cookie_consent_marketing.set(consent_marketing);
    if (consent_statistics) cookie_consent_statistics.set(consent_statistics);

    cookie_consent_preferences.subscribe((value) => {
        if (!value) return;
        localStorage.setItem('cookie_consent_preferences', `${value}`);
    });

    cookie_consent_asked.subscribe((value) => {
        let prefs = get(cookie_consent_preferences);
        if (!prefs) return;
        localStorage.setItem('cookie_consent_asked', `${value}`);
    });

    cookie_consent_marketing.subscribe((value) => {
        let asked = get(cookie_consent_asked);
        if (!asked) return;
        localStorage.setItem('cookie_consent_marketing', `${value}`);
    });

    cookie_consent_statistics.subscribe((value) => {
        let asked = get(cookie_consent_asked);
        if (!asked) return;
        localStorage.setItem('cookie_consent_statistics', `${value}`);
    });
}


/**
 * @type {import('svelte/store').Writable<DiscountData>}
 */
export const discount_data = writable({
    active: false
});

/** @type {import('svelte/store').Writable<Promise<string[]>>} */
export const purchased_courses = writable(Promise.resolve([]));

export const completed_courses = writable(Promise.resolve([]));



<script>
    import { goto } from "$app/navigation";

    import { createEventDispatcher } from "svelte";
    import Icon from "./Icon.svelte";

    let className = ''
	export { className as class }
 
    export let nop = false;
    export let xxs = false;
    export let xs = false;
    export let sm = false;
    export let lg = false;
    export let xl = false;
    export let xxl = false;
 
    export let primary = false;
    export let danger = false;
    export let faded = false;
    export let link = false;

    export let outline = false;
    export let invert = false;
    export let text = false;
    
    export let pill = false;

    export let right = false;
    export let left = false;

    export let rounded = true;

    export let block = false;
    
    export let submit = false;
    export let disabled = false;

    export let stopPropagation = false;
    export let preventDefault = false;
    
    export let animated = true;

    export let route = '';
    export let loading = false;
    export let loading_spinner = false;


    const dispatch = createEventDispatcher();

    const handleClick = (e)=> {
        if (stopPropagation) e.stopPropagation();
        if (preventDefault) e.preventDefault();

        if (route) {
            goto(route);
            return;
        }

        dispatch('click', e);
    }

</script>

<button
    on:click="{handleClick}"
    disabled={disabled || loading}
    type="{submit ? 'submit' : 'button'}"
    class="{className}  
        align-middle
        select-none
        transition-all
        relative
        {
            pill ? 'rounded-full' 
            : rounded ? 'rounded-lg' 
            : ''
        }

        {
            primary && outline && invert ? 'border border-orange-600 text-orange-600 enabled:hover:bg-orange-600 enabled:hover:bg-opacity-5 dark:border-orange-700'
            : primary && outline ? 'border border-orange-500 text-orange-500 enabled:hover:bg-orange-500 enabled:hover:bg-opacity-5 dark:border-orange-700'
            : primary && text ? ' text-orange-500'
            : danger && outline ? 'border border-red-500 text-red-500 enabled:hover:bg-black enabled:hover:bg-opacity-5'
            : danger && text ? ' text-red-500'
            : outline && invert ? 'text-inherit border border-border-invert-default enabled:hover:border-border-invert-highlight'
            : outline ? 'text-inherit border border-border-default enabled:hover:border-border-highlight'
            : primary? 'text-orange-50 bg-orange-500 enabled:hover:bg-orange-600 shadow-lg dark:bg-orange-700 dark:enabled:hover:bg-orange-800' 
            : danger ? 'text-red-50 bg-red-500 enabled:hover:bg-red-600 shadow-lg dark:bg-red-700 dark:enabled:hover:bg-red-800'
            : faded ? 'text-gray-50 bg-gray-500 enabled:hover:bg-gray-600'
            : ''
        }

        {link ? 'underline underline-offset-auto' : ''}

        {animated && !text && !outline ? 'active:shadow-sm active:translate-y-1' : ''}

        {
            xxs ? 'text-xs' 
            : xs ? 'text-xs' 
            : sm ? 'text-sm'
            : lg ? 'text-lg'
            : xl ? 'text-xl'
            : xxl ? 'text-2xl'
            : ''
        }

        {
            nop || xxs ? '' 
            : xs ? 'px-2 py-1' 
            : sm ? 'px-2 py-1'
            : lg ? 'px-4 py-2'
            : xl ? 'px-5 py-3'
            : xxl ? 'px-5 py-3'
            : 'px-4 py-2'
        }

        {
            left ? 'text-left pl-0'
            : right ? 'text-right pr-0'
            : 'text-center'
        }

        {block ? 'w-full' : '' }

        
        

        {disabled ? 'opacity-50' : ''}
        {loading ? '!text-transparent cursor-wait' : ''}
        {loading && !loading_spinner ? 'animate-pulse' : ''}
        {loading && loading_spinner ? 'bg-opacity-80' : ''}
    "
>
    <slot></slot>
    {#if $$slots.small}
        <small class="block {
            xxs ? 'text-xs' 
            : xs ? 'text-xs' 
            : sm ? 'text-xs'
            : lg ? 'text-sm'
            : xl ? 'text-md'
            : xxl ? 'text-lg'
            : ''
        }"><slot name="small"></slot></small>
    {/if}
    {#if loading && loading_spinner}
        <Icon spin icon="loading" class="absolute top-[calc(50%-.75rem)] left-[calc(50%-.75rem)]
        {
            primary && outline ? 'text-orange-500'
            : primary && text ? ' text-orange-500'
            : danger && outline ? 'text-red-500'
            : danger && text ? ' text-red-500'
            : outline && invert ? 'text-black'
            : outline ? 'text-white'
            : primary? 'text-orange-50' 
            : danger ? 'text-red-50'
            : faded ? 'text-gray-50' 
            : ''
        }"/>
    {/if}
</button>

<style lang="postcss">
    button :global(svg) {
        vertical-align: middle; 
    }
</style>

